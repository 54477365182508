import React from 'react'
import logo from 'assets/icons/logo.png'
import telephone from 'assets/icons/telephone.svg'
import email from 'assets/icons/email.svg'
import location from 'assets/icons/location.svg'
import instagram from 'assets/icons/instagram.svg'
import facebook from 'assets/icons/facebook.svg'
import whatsapp from 'assets/icons/whatsapp.svg'
import youtube from 'assets/icons/youtube.svg'
import tiktok from 'assets/icons/tiktok.svg'

import { Link } from 'react-router-dom'

function Footer() {
  return (
    <footer className='flex w-full flex-col items-center justify-center bg-[#474642]'>
      <div className='my-10 flex w-11/12 flex-col justify-center gap-8 text-white md:my-20 md:w-10/12 md:flex-row md:gap-12'>
        <div className='hidden flex-1 flex-col gap-4 md:flex'>
          <picture>
            <img src={logo} alt='Logo Ictus - Soluções em Energia' loading='lazy' className='w-64' />
          </picture>
        </div>
        <div className='flex flex-1 flex-col gap-4'>
          <header className='text-3xl font-medium md:text-4xl'>Contato</header>
          <ul className='flex flex-col gap-2'>
            <li>
              <a
                href='https://api.whatsapp.com/send/?phone=554436498400&text&type=phone_number&app_absent=0'
                target='_blank'
                className='flex items-center gap-2 text-base' class="h-widget-trigger"
              >
                <img src={telephone} alt='Icone do telefone' className='h-8 w-8' />
                <span className='transition-all duration-500 hover:text-[#50af8a]'>(44) 3649-8400</span>
              </a>
            </li>
            <li>
              <a href='mailto:orcamento@ictus.solar' target='_blank' className='flex items-center gap-2 text-base'>
                <img src={email} alt='Icone do Email' className='h-8 w-8' />
                <span className='transition-all duration-500 hover:text-[#50af8a]'>orcamento@ictus.solar</span>
              </a>
            </li>
            <li>
              <a
                href='https://maps.app.goo.gl/Coi5x3Ktf9WA5yFQ9'
                target='_blank'
                className='flex items-center gap-2 text-base'
              >
                <img src={location} alt='Icone de localização' className='h-8 w-8' />
                <span className='transition-all duration-500 hover:text-[#50af8a]'>
                  Rua Primeiro de Janeiro, 2668 Imigrantes, Palotina, PR, 85950-000
                </span>
              </a>
            </li>
          </ul>
        </div>

        <div className='flex flex-1 flex-col gap-4'>
          <header className='text-3xl font-medium md:text-4xl'>Institucional</header>
          <ul className='flex flex-col gap-2'>
            <li>
              <a
                href='https://ictus.solar'
                className='flex items-center gap-2 text-base transition-all duration-500 hover:text-[#50af8a]'
              >
                Página Inicial
              </a>
            </li>
            <li>
              <Link
                to='https://ictus.solar/simulacao'
                className='flex items-center gap-2 text-base transition-all duration-500 hover:text-[#50af8a]'
              >
                Faça uma Simulação
              </Link>
            </li>
            <li>
              <Link
                to='https://ictus.solar/depoimentos'
                className='flex items-center gap-2 text-base transition-all duration-500 hover:text-[#50af8a]'
              >
                Depoimentos
              </Link>
            </li>
            <li>
              <Link
                to='https://ictus.solar/contatos'
                className='flex items-center gap-2 text-base transition-all duration-500 hover:text-[#50af8a]'
              >
                Contatos
              </Link>
            </li>
            <li>
              <Link
                to='https://ictus.solar/sobre'
                className='flex items-center gap-2 text-base transition-all duration-500 hover:text-[#50af8a]'
              >
                Sobre
              </Link>
            </li>
          </ul>
        </div>

        <div className='flex flex-1 flex-col gap-4'>
          <ul className='flex items-center gap-4'>
            <li>
              <a
                href='https://www.instagram.com/ictusbr/'
                target='_blank'
                className='flex items-center gap-2 text-base'
              >
                <img src={instagram} alt='Icone do Instagram' className='h-14 w-14 hover:opacity-50 md:h-14 md:w-14' />
              </a>
            </li>
            <li>
              <a href='https://www.facebook.com/ictusbr/' target='_blank' className='flex items-center gap-2 text-base'>
                <img src={facebook} alt='Icone do Facebook' className='h-14 w-14 hover:opacity-50 md:h-14 md:w-14' />
              </a>
            </li>
            <li>
              <a
                href='https://api.whatsapp.com/send/?phone=554499440094&text&type=phone_number&app_absent=0'
                target='_blank'
                className='flex items-center justify-center gap-1' class="h-widget-trigger"
              >
                <img src={whatsapp} alt='Icone do WhatsApp' className='h-14 w-14 hover:opacity-50 md:h-14 md:w-14' />
              </a>
            </li>
            <li>
              <a
                href='https://www.youtube.com/@ictusbrasil'
                target='_blank'
                className='flex items-center gap-2 text-base'
              >
                <img src={youtube} alt='Icone do WhatsApp' className='h-16 w-16 hover:opacity-50 md:h-14 md:w-14' />
              </a>
            </li>
            <li>
              <a
                href='https://www.tiktok.com/ictusbr'
                target='_blank'
                className='flex items-center gap-2 text-base'
              >
                <img src={tiktok} alt='Icone do TikTok' className='h-16 w-16 hover:opacity-50 md:h-14 md:w-14' />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className='mb-16 flex w-full flex-col items-center justify-center gap-1 p-4 text-xs text-white md:flex-row md:justify-around'>
        <a href='#' className='transition-all duration-500 hover:text-[#50af8a]'>
          ©COPYRIGHT ICTUS SOLUÇÕES EM ENERGIA 2024.
        </a>
        <a href='https://dorac.com.br' target='_blank' className='transition-all duration-500 hover:text-[#50af8a]'>
          DESENVOLVIDO POR <span className='underline'>AGÊNCIA DORAC</span>
        </a>
        <a href='#' className='underline transition-all duration-500 hover:text-[#50af8a]'>
          POLÍTICA DE PRIVACIDADE
        </a>
      </div>
    </footer>
  )
}

export default Footer
